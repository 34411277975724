exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-library-for-practitioners-js": () => import("./../../../src/pages/library/for-practitioners.js" /* webpackChunkName: "component---src-pages-library-for-practitioners-js" */),
  "component---src-pages-library-js": () => import("./../../../src/pages/library.js" /* webpackChunkName: "component---src-pages-library-js" */),
  "component---src-pages-library-most-popular-js": () => import("./../../../src/pages/library/most-popular.js" /* webpackChunkName: "component---src-pages-library-most-popular-js" */),
  "component---src-pages-library-read-more-js": () => import("./../../../src/pages/library/read-more.js" /* webpackChunkName: "component---src-pages-library-read-more-js" */),
  "component---src-pages-library-research-js": () => import("./../../../src/pages/library/research.js" /* webpackChunkName: "component---src-pages-library-research-js" */),
  "component---src-pages-library-sell-sheets-js": () => import("./../../../src/pages/library/sell-sheets.js" /* webpackChunkName: "component---src-pages-library-sell-sheets-js" */),
  "component---src-pages-library-symptom-related-js": () => import("./../../../src/pages/library/symptom-related.js" /* webpackChunkName: "component---src-pages-library-symptom-related-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-returns-js": () => import("./../../../src/pages/returns.js" /* webpackChunkName: "component---src-pages-returns-js" */),
  "component---src-pages-sell-our-products-js": () => import("./../../../src/pages/sell-our-products.js" /* webpackChunkName: "component---src-pages-sell-our-products-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */)
}

